import { link, tokenLink, dnsPortal, urlADM } from "../../services/variables";

//alguns link possuem token diferente do padrão
const linksNavbar = [
  //Município
  {
    name: `Município`,
    top_list: true,
    img: "Municipio",
    sub_link: [
      {
        name: `TV Câmara`,
        link: `/municipio/tv-camara`,
      },
      {
        name: `Distâncias`,
        link: `/municipio/distancias`,
      },
      {
        name: `História`,
        link: `/municipio/historia`,
      },
      // {
      //   name:`População`,
      //   link:`/municipio/numeros`
      // },
      {
        name: `Mapa`,
        link: `/municipio/mapa`,
      },
      // {
      //   name: `Símbolos`,
      //   link: `/municipio/simbolos`,
      // },
      {
        name: `Endereços Úteis`,
        link: `/municipio/enderecos-uteis`,
      },
      // {
      //   name:`Distritos`,
      //   link:`/municipio/distritos`
      // },
      // {
      //   name:`Eventos`,
      //   link:`/municipio/eventos`
      // },
      // {
      //   name:`Obras`,
      //   link:`${link}/portaltm/licitacao/obrapublica.xhtml?token=${tokenLink}`
      // },
      // {
      //   name:`Veículos`,
      //   link:`/municipio/veiculos`
      // },
    ],
  },
  //Vereadores
  {
    name: `Vereadores`,
    top_list: true,
    img: "justice",
    sub_link: [
      {
        name: `Registro das Competências`,
        link: `/vereadores/registro-das-competencias`,
      },
      {
        name: `População X Vereadores`,
        link: `/vereadores/populacao-x-vereadores`,
      },
      {
        name: `Termo de Posse dos Vereadores`,
        link: `${urlADM}/admpublica/termo-de-posse-dos-vereadores?token=${tokenLink}`,
      },
    ],
  },
  //Legislativo Municipal
  {
    name: `Legislativo Municipal`,
    top_list: true,
    img: "Institucional",
    sub_link: [
      /* {
        name: `Mural`,
        link: `/legislativo-municipal/mural`,
      }, */
      {
        name: `Estrutura Organizacional`,
        link: `/estrutura-organizacional`,
      },
      {
        name: `Concurso Público`,
        link: `${urlADM}/licitacoes-e-contratos/concurso?token=${tokenLink}`,
      },
      {
        name: `Legislação`,
        sub_link: [
          {
            name: `Regimento interno`,
            link: `${urlADM}/admpublica/regimento-interno?token=${tokenLink}`,
          },
          {
            name: `Lei Orgânica`,
            link: `${urlADM}/admpublica/lei-organica?token=${tokenLink}`,
          },
          // {
          //   name:`Código Tributário`,
          //   link:`/legislativo-municipal/legislacao/estaduais/codigo-tributario`
          // },
          {
            name: `Leis Municipais`,
            link: `${urlADM}/admpublica/lei-ordinaria?token=${tokenLink}`,
          },
          {
            name: `Resoluções`,
            link: `${urlADM}/admpublica/resolucoes?token=${tokenLink}`,
          },
          {
            name: `Decretos`,
            link: `${urlADM}/admpublica/decreto?token=${tokenLink}`,
          },
          {
            name: `Portarias`,
            //versão conjunta
            link: `${urlADM}/admpublica/portarias?token=${tokenLink}`,
          },
          {
            name: `Outros Atos Administrativos`,
            link: `${urlADM}/admpublica/outros-atos-administrativos?token=${tokenLink}`,
          },
        ],
      },
      {
        name: `Cotas das atividades Parlamentar/Verba`,
        link: `${urlADM}/admpublica/cotas-para-exercicio-da-atividade-parlamentar-verba-indenizatoria?token=${tokenLink}`,
      },
      {
        name: `Legislação relacionada a gastos dos parlamentares`,
        link: `${urlADM}/admpublica/legislacao-relacionada-a-gastos-dos-parlamentares?token=${tokenLink}`,
      },
      {
        name: `Projetos de leis e atos infralegais`,
        link: `${urlADM}/admpublica/projetos-e-atividades-legislativas?token=${tokenLink}`,
      },
      {
        name: `Pauta das Comissões e das Sessões do Plenário`,
        link: `${urlADM}/admpublica/pauta-das-comissoes-e-das-sessoes-do-plenario?token=${tokenLink}`,
      },
      {
        name: `Ata de Sessões`,
        link: `${urlADM}/admpublica/sessoes?token=${tokenLink}`,
      },
      {
        name: `Votação nominais e/ou unânimes`,
        link: `${urlADM}/admpublica/votacoes-nominais-dos-projetos-de-leis?token=${tokenLink}`,
      },
      {
        name: `Presenças e Ausências dos Parlamentares`,
        link: `${urlADM}/admpublica/controle-de-frequencia-dos-vereadores?token=${tokenLink}`,
      },
      {
        name: `Julgamento das Contas do Governador/Prefeito`,
        link: `${urlADM}/admpublica/julgamento-das-contas-anuais?token=${tokenLink}`,
      },
      {
        name: `Resultado Inspeções, prestações e tomadas de contas`,
        link: `${urlADM}/admpublica/resultados-de-inspecoes-auditorias-prestacoes-e-tomadas-de-contas?token=${tokenLink}`,
      },
    ],
  },
  //Diário Oficial
  {
    name: `Diário Oficial`,
    top_list: true,
    img: "Diario_OFI",
    sub_link: [
      {
        name: `Diário Oficial Eletrônico`,
        link: `${urlADM}/diario-oficial?token=${tokenLink}`,
      },
      {
        name: `Lei de criação do Diário`,
        link: `${urlADM}/admpublica/lei-de-criacao-do-diario-oficial-eletronico-do-municipio?token=${tokenLink}`,
      },
      {
        name: `Ato de nomeação do responsável`,
        link: `${urlADM}/admpublica/ato-de-nomeacao-de-pessoa-responsavel-pelas-publicacoes-do-diario-oficial-do-municipio?token=${tokenLink}`,
      },
    ],
  },
  //Pessoal
  {
    name: `Pessoal`,
    top_list: true,
    img: "Pessoal",
    sub_link: [
      // {
      //   name: `Contracheque`,
      //   link: `#`,
      // },
      {
        name: `Estatuto do Servidor`,
        link: `${urlADM}/admpublica/estatuto-dos-servidores?token=${tokenLink}`,
      },
      {
        name: `Folha de pagamento`,
        link: `http://www.transparenciadministrativa.com.br/inforfolha/contracheque/remuneracao.xhtml?token=9561463a-ba2d-47d7-a71d-b6b3598324eb`,

        // sub_link:[
        //   {
        //     name:`Gestão Atual`, //
        //     link:`#` //link diferente
        //   },
        //   {
        //     name:`Gestão Anterior`,
        //     link:`#`
        //   },
        // ]
      },
      {
        name: `Remuneração Nominal de cada servidor`,
        link: `${urlADM}/admpublica/relacao-nominal-de-cada-servidor?token=${tokenLink}`,
      },
      {
        name: `Servidores inativos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-inativos?token=${tokenLink}`,
      },
      {
        name: `Servidores ocupante de cargo em comissão`,
        link: `${urlADM}/admpublica/relacao-de-servidores-ocupantes-de-cargo-em-comissao?token=${tokenLink}`,
      },
      {
        name: `Servidores cedidos para outros Órgãos Públicos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-cedidos-para-outros-orgaos-publicos?token=${tokenLink}`,
      },
      {
        name: `Relação nominal dos estagiários`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-estagiarios?token=${tokenLink}`,
      },
      {
        name: `Relação de contratados temporariamente e contratos de terceirização`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao?token=${tokenLink}`,
      },
      {
        name: `Tabela com o padrão remuneratório dos cargos e função`,
        link: `${urlADM}/admpublica/tabela-remuneratoria-dos-cargos-e-funcoes?token=${tokenLink}`,
      },
    ],
  },
  // Diárias
  {
    name: `Diárias`,
    top_list: true,
    img: "Diárias",
    sub_link: [
      {
        name: `Relação das Diárias`,
        link: `${urlADM}/admpublica/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais?token=${tokenLink}`,
      },
      {
        name: `Diárias e Passagens`,
        link: `http://143.137.254.24:5656/SCPIWEB_CMMATOES/Default.aspx?AcessoIndividual=lnkDiarias`,
      },
      {
        name: `Legislação`,
        link: `${urlADM}/admpublica/diarias-e-legislacao?token=${tokenLink}`,
      },
    ],
  },
  // Transparência
  {
    name: `Transparência`,
    link: `/transparencia`,
    top_list: true,
    img: "Transparência",
  },
  // Licitações e Contratos
  {
    name: `Licitações e Contratos`,
    top_list: true,
    img: "licitaçoes",
    sub_link: [
      {
        name: `Indicação do Fiscal de Contrato`,
        link: `/licitacoes-e-contratos/indicacao-do-fiscal-do-contrato`,
      },
      {
        name: `Avisos`,
        link: `/licitacoes-e-contratos/avisos`,
      },
      {
        name: `Edital`,
        link: `/licitacoes-e-contratos/edital`,
      },
      {
        name: `Convênio`,
        sub_link: [
          {
            name: `Recebidas`,
            link: `${urlADM}/admpublica/divisao/declaracoes-recebidas?token=${tokenLink}`,
          },
          {
            name: `Realizadas`,
            link: `${urlADM}/admpublica/divisao/declaracoes-realizadas?token=${tokenLink}`,
          },
          {
            name: `Acordos/Ajustes`,
            link: `${urlADM}/admpublica/acordos-sem-transferencias-de-recursos?token=${tokenLink}`,
          },
        ],
      },
      {
        name: `Dispensas`,
        link: `/licitacoes-e-contratos/dispensas`,
      },
      {
        name: `Inexigibilidade`,
        link: `/licitacoes-e-contratos/inexigibilidade`,
      },
      {
        name: `Adesão de Ata - SRP `,
        link: `/licitacoes-e-contratos/ata-de-adesao`,
      },
      {
        name: `Resultados do Certame`,
        link: `/licitacoes-e-contratos/resultado-do-certame`,
      },
      {
        name: `Desertas e Fracassadas`,
        link: `/licitacoes-e-contratos/desertas-fracassadas`,
      },
      {
        name: `Pregão Eletrônico`,
        link: `/licitacoes-e-contratos/pregao-eletronico`,
      },
      {
        name: `Obras Públicas`,
        link: `/licitacoes-e-contratos/obras-publicas`,
      },
      {
        name: `Termo de Aditivo`,
        link: `/licitacoes-e-contratos/termo-de-aditivo`,
      },
      {
        name: `Contratos `,
        link: `/licitacoes-e-contratos/contrato`,
      },
      {
        name: `Fornecedores Sancionados`,
        link: `${urlADM}/licitacoes-e-contratos/fornecedores-sancionados?token=${tokenLink}`,
      },
    ],
  },
  // Ouvidoria
  {
    name: `Ouvidoria`,
    top_list: true,
    img: "Ouvidoria",
    sub_link: [
      {
        name: `Regulamentação`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-da-ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Atendimento Presencial`,
        link: `/ouvidoria/atendimento-presencial`,
      },
      {
        name: `Carta de Serviços ao Usuário`,
        link: `${urlADM}/downloads?n=01561963000127&a=atos_administrativos_20022ca7-7873-42a2-b5fb-1ef64acc940d.pdf`,
      },
      {
        name: `Redes Sociais`,
        link: `/redes-sociais`,
      },
      {
        name: `Canal Eletrônico de Acesso`,
        link: `${urlADM}/ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Perguntas e Respostas`,
        link: `${urlADM}/ouvidoria/acompanhamento?token=${tokenLink}`,
      },
      // {
      //   name:``,
      //   link:`/ouvidoria/`,
      // },

      // {
      //   name:`Detalhe`,
      //   link: `/ouvidoria/detalhe`
      // },
    ],
  },
  // Cidadão | e-SIC
  {
    name: `Cidadão | e-SIC `,
    top_list: true,
    img: "Cidadão",
    sub_link: [
      {
        name: `e-SIC - Regulamentação`,
        link: `${urlADM}/esic?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Perguntas e Respostas`,
        link: `${urlADM}/e-sic/perguntas-e-respostas?token=${tokenLink}`,
      },
      {
        name: `e-SIC`,
        link: `${urlADM}/e-sic?token=${tokenLink}`,
      },
      {
        name: `Portaria do responsável pelo e-SIC`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-esic?token=${tokenLink}`,
      },
      {
        name: `Instrumento normativo local que regulamente a LAI`,
        link: `${urlADM}/acesso-a-informacao?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Rol de Informações (12 meses)`,
        link: `${urlADM}/admpublica/rol-das-informacoes-que-tenham-sido-desclassificadas-nos-ultimos-12-doze-meses?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Rol de documentos (Grau Sigilo)`,
        link: `${urlADM}/admpublica/rol-de-documentos-classificados-em-cada-grau-de-sigilo-com-identificacao-para-referencia-futura?token=${tokenLink}`,
      },
    ],
  },
  {
    name: `LGPD`,
    top_list: true,
    img: "lgpd",
    link: `${urlADM}/lgpd?token=${tokenLink}`,
  },
  {
    name: `Usuário`,
    top_list: true,
    img: "admin-navbar",
    sub_link: [
      {
        name: `Login - Portal`,
        link: `https://noticias.administracaopublica.com.br/auth/login`,
      },
      {
        name: `Login - Licitação`,
        link: `${link}/licitacaotm/login.xhtml`,
      },
      {
        name: `Login - Atendimento`,
        link: `https://atendimento.administracaopublica.com.br/login`,
      },
      {
        name: `WebMail`,
        link: `https://webmail-seguro.com.br/${dnsPortal}`,
      },
    ],
  },
];
export default linksNavbar;
